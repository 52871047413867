import '@deromeab/components/dist/esm/index.css';
import './styles/index-order-tracker.scss';

import { Footer, HeaderPublic } from '@deromeab/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageSpinner } from './components/PageSpinner';
import { OrderTracker } from './pages/order-tracker/OrderTracker';
import { getOrderTracker } from './queries/get-order-tracker';
import { getShipmentSummary } from './queries/get-shipment-summary';
import { getShipmentSummaryLegacy } from './queries/get-shipment-summary-fallback';

const gqlClient = new QueryClient();

export default function NotificationAppOrderTracker() {
  const navigate = useNavigate();
  const { hash, orderNo: paramOderNo } = useParams();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [isVissibleDebug, toggleDebug] = useState(true);

  const shipmentReq = getShipmentSummary(hash!, !!hash);
  const legacyReq = getShipmentSummaryLegacy(paramOderNo!, !hash);

  const { data, isLoading: isLoadingSummary } = shipmentReq;
  const { data: legacyData, isLoading: isLoadingLegacyQuery } = legacyReq;

  const summary =
    data?.getShipmentSummaryFromHash || legacyData?.getShipmentSummary;

  const shipmentOrderNo = summary?.orderNo!;
  const trackerReq = getOrderTracker(
    summary?.orderNo || paramOderNo!,
    !!summary,
  );

  const customerId = summary?.customerId!;
  const registered = summary?.registered!;

  useEffect(() => {
    if (!isLoadingSummary && !isLoadingLegacyQuery && !data) {
      navigate('/not-found');
    }
  }, [isLoadingSummary]);

  return (
    <QueryClientProvider client={gqlClient}>
      <div className="order-tracker-content-body">
        <HeaderPublic
          homeUrl={() => window.location.replace('https://www.derome.se/')}
        />

        <div className="order-tracker-content-wrapper">
          {isLoadingSummary || isLoadingLegacyQuery ? (
            <PageSpinner />
          ) : (
            <OrderTracker
              orderNo={shipmentOrderNo}
              // TODO: fix type err from Maybe variant to nullable
              // @ts-ignore
              shipmentSummary={summary}
              customerId={customerId}
              registered={registered}
            />
          )}
        </div>

        {params.get('debug') && (
          <div className="debug">
            <div onClick={() => toggleDebug(!isVissibleDebug)}>Show/Hide</div>

            <div className="debug-window">
              {isVissibleDebug &&
                trackerReq.data?.getOrderTracker?.logs.map((log, i) => {
                  try {
                    log.text = JSON.parse(log.text);
                  } catch (_) {}

                  return <pre key={i}>{JSON.stringify(log, null, 2)}</pre>;
                })}
            </div>
          </div>
        )}

        <Footer
          topicLinks={[
            {
              title: 'Om Derome',
              links: [
                {
                  text: 'Våra verksamheter',
                  url: 'https://www.derome.se/om-derome/derome-verksamheten',
                },
                {
                  text: 'Hållbarhet',
                  url: 'https://www.derome.se/hallbarhet',
                },
                {
                  text: 'Karriär',
                  url: 'https://www.derome.se/om-derome/karriar',
                },
                {
                  text: 'Press och media',
                  url: 'https://www.derome.se/om-derome/press-media',
                },
                {
                  text: 'Nyheter',
                  url: 'https://www.derome.se/om-derome/press-media/nyheter',
                },
                {
                  text: 'Kundsupport',
                  url: 'https://kundsupport-foretag.derome.se/s/',
                },
              ],
            },
          ]}
        />
      </div>
    </QueryClientProvider>
  );
}
