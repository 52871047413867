import '@deromeab/components/dist/esm/index.css';

import { GenericInfo } from '@deromeab/components';

interface Props {
  marking: string;
  orderNo: string;
  cfcMarking: string;
  customerPoNo: string;
  shipViaCode?: string;
  shipmentId?: string;
  pickup?: {
    city?: string;
    name?: string;
    address?: string;
    zipCode?: string;
  };
}

export const CustomerInfo = ({
  marking,
  orderNo,
  cfcMarking,
  customerPoNo,
}: Props) => {
  const customerInfo = [];

  if (marking) {
    customerInfo.push([
      {
        title: 'Projekt',
        items: [marking],
      },
    ]);
  }

  if (cfcMarking)
    customerInfo.push([{ title: 'Märkning', items: [cfcMarking] }]);

  if (customerPoNo)
    customerInfo.push([{ title: 'ISO-nr', items: [customerPoNo] }]);

  customerInfo.push([
    {
      title: 'Ordernr',
      items: [orderNo],
    },
  ]);

  if (!customerInfo.length) {
    return <></>;
  }

  return <GenericInfo data={customerInfo} />;
};
